<template>
  <div>
    <!-- {{ currency }}
    {{ 1200 | ex }} -->
    <!-- <button @click="$store.commit('SET_CURRENT_CURRENCY', 'USD')">CHANGE CURRENCY</button> -->
    <!-- <button @click="test3">CLICK HERE</button> -->
    <!-- {{ searchItems }} -->
    <!-- <button @click="test5">CLICK HERE</button> -->
    <button @click="test6">CLICK HERE</button>
  </div>
</template>

<script>
import { makeOnlinePayment, verifyPayment, testFunc } from "./../services/cloudFunctions";
import { mapGetters } from 'vuex'
import { addOrder } from "./../api/orders";
import shortid from "shortid";
import axios from 'axios'
import { productsRef, productsBkpRef, searchRef, firestore, metaRef } from './../services/database'
import { getCoupon } from './../api/coupons'

export default {
  metaInfo: {
    script: [
      {
        src: "https://checkout.razorpay.com/v1/checkout.js",
        async: true,
        defer: true,
      },
    ],
  },
  data : () => ({
      modal: false,
      user: {
        name: "John Doe",
        email: "johndoe@email.com",
        phone: 9876543210
      },
      searchItems: []
  }),
  computed: {
    ...mapGetters(['currency'])
  },
  methods: {
    test5() {
      let increment = firestore.FieldValue.increment(1);
      const orderCountDocRef = metaRef.doc("orderCount")
      orderCountDocRef.update({ lastOid: increment })
      .then(() => console.log("done"))

    },
    test4() {
      let items = []
      productsRef.where('isLive', '==', true).get().then(snaps => {
        snaps.forEach(snap => {
          // this.searchItems = snap.data()
          items.push(snap.data())
        })
        this.searchItems = items.map(item => item.name)        
      })
    },
    async test3() {
      let snaps = await productsRef.get()
      let products = []

      for(let snap of snaps.docs) {
        await productsRef.doc(snap.id).update({
          startingPrice: snap.data().price[0]
        })
        console.log("done")
      }




      // searchStoreRef.update({
        // items: Array.from({ length: 50 }, (_, i) => "pure dark jeans"),
      //   items: products.map(item => item.name),
      // })
      // .then(() => {
      //   console.log("done");
      // });

      // db.doc("test/RaI0ugVPTd4KStgzGv0k")
      //   .update({
      //     items: Array.from({ length: 500 }, (_, i) => "pure dark jeans"),
      //   })
      //   .then(() => {
      //     console.log("done");
      //   });

    },
    test2() {
      testFunc()
      .then(() => {
        console.log("done")
      })
      .catch(err => console.log(err))
    },
    test1() {
      let currencies = [
        { name: "INR", symbol: "₹", rate: 1 },
        { name: "USD", symbol: "$", rate: 0.013 },
        { name: "EUR", symbol: "€", rate: 0.012 },
        { name: "GBP", symbol: "£", rate: 0.01 },      
        { name: "AUD", symbol: "$", rate: 0.019 },      
        { name: "CAD", symbol: "$", rate: 0.017 },      
        { name: "AED", symbol: "Dhs. ", rate: 0.05 },      
      ]

      axios
        .put("https://harshita-jain-default-rtdb.asia-southeast1.firebasedatabase.app/currencies.json", currencies)
        .then(() => {
          console.log("done")
        })
        
    },

    async test6(){
      try {      
        console.log("ee")
        const discount = await getCoupon('HJ10')
        let total = 1500

        if(total < discount.minAmount) {
          console.log("please add at least " + discount.minAmount)
          return
        } 
        const discountAmount = total * (discount.rate * 0.01)
        const cartTotal = discountAmount >= discount.maxDiscount? total - discount.maxDiscount : total - discountAmount
        
        console.log(cartTotal)        
      } catch (error) {
        console.log(error)
      }
    },
    
    async test() {

        // verifyPayment({
        //   docId: 'Qh0rglq7Gccyu3dOP93J',
        //   paymentOrderId: 'order_I4T2ceFo1H3oea'
        // })
        // .then(res => {
        //   console.log(res)
        // })        
        // .catch(e => console.log(e))

        // return

      let order = {
        id: shortid.generate(),
        items: ["apple", "grapes"],
        amount: 2,
      };

      try {
        console.log("creating order...")
        let res = await addOrder(order);

        console.log("creating payment...")
        let res_1 = await makeOnlinePayment(res); // createOnlinePayment        
        
        let res_2 = await this.payOnline(res_1.data)
        // console.log(res.id, res_1.data.id)
        
        console.log(res_2, "payment complete. Verifying...")

        let res_3 = await verifyPayment({
          docId: res.id,
          paymentOrderId: res_1.data.id
        })        

        console.log(res_3)

      } catch (error) {
        console.log(error)
      }

    },
    payOnline(data) {
      return new Promise(async (resolve, reject) => {
        var thisRef = this;

        var options = {
          key: "rzp_test_CfukWjsBEzOtM2",
          currency: data.currency,
          amount: data.amount.toString(),
          order_id: data.id,
          name: "Complete Payment",
          description: "2 items",          
          modal: {
            ondismiss: function() {
              thisRef.showModal = false;
            //   thisRef.$nextTick(() => {
            //     thisRef.$toasted.error(thisRef.errorData);
            //   });
            },
          },
          config: {
            display: {
              blocks: {
                upi: {
                  name: "UPI apps or id",
                  instruments: [
                    {
                      method: "upi",
                      // apps: ["google_pay", "phonepe"]
                    },
                  ],
                },
                card: {
                  name: "Credit Card, Debit Card",
                  instruments: [
                    {
                      method: "card",
                    },
                  ],
                },
                wallet: {
                  name: "Wallet",
                  instruments: [
                    {
                      method: "wallet",
                    },
                  ],
                },
              },
              hide: [
                {
                  method: "netbanking",
                },
              ],
              sequence: ["block.upi", "block.card", "block.wallet"],
              preferences: {
                show_default_blocks: false, // Should Checkout show its default blocks?
              },
            },
          },
          handler: (res) => {
            resolve(res);
          },
          prefill: {
            name: this.user.name,
            email: this.user.email,
            contact: this.user.phone,
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.on("payment.failed", function(response) {
          thisRef.showModal = false;
          reject(response);
        });

        paymentObject.open();
      });
    },
  },
};
</script>
